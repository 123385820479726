<template>
  <div class="news_block">

    <SliderNews />

    <div class="news_list_block">
      <div class="news_list_container">

<!--        <div class="news_list_block_sorting">-->
<!--          <div class="news_list_container_sorting">-->
<!--            <div class="news_list_sorting">-->
<!--              <div class="news_list_sorting_label">{{ $t('Sorting') }}</div>-->
<!--              <div class="news_list_radio_block">-->
<!--                <input type="radio" name="sort" id="newest" class="news_list_radio1" @change="sort" disabled/>-->
<!--                <label for="newest" class="radio_label1">{{ $t('New_ones_first') }}</label>-->
<!--                <input type="radio" name="sort" id="popular" class="news_list_radio1" @change="sort" disabled/>-->
<!--                <label for="popular" class="radio_label2">{{ $t('Popular') }}</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!-- todo: add img null placeholder -->
        <!-- todo: add current locale in translations -->
        <div class="news_list_desc">
          <div class="news_list_item" v-for="item in news">
            <img :src="item.image ? getImagePath(item.image.path) : ''" alt="Изобрражение новости" />
            <div class="news_list_item_desc">
              <div class="news_list_item_title">
                {{ item.translations.find(({locale}) => locale === currentLocale).title }}
              </div>
              <div class="news_list_item_about">
                {{item.translations.find(({locale}) => locale === currentLocale).anons}}
              </div>
              <div class="news_list_item_date">{{ $t('published') }}: {{item.published_date | formatDate('DD.MM.YY')}}</div>
              <router-link
                :to="{ name: 'news-item', params: { id: item.id } }"
                class="news_list_item_btn_more"
                exact
              >
                <span>{{ $t('more') }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="nextPageApiUrl" @click="loadMore" class="news_list_load_more">
          Загрузить ещё
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SliderNews from "./SliderNews";

  export default {
    name: "News",
    components: {SliderNews},
    data() {
      return {
        news: [],
        currentPage: 1,
        totalPages: null,
        perPage: null,
        nextPageApiUrl: null,
        currentLocale : this.$i18n.locale,
      };
    },
    methods: {
      dataFromApi(data) {
        this.currentPage = data.current_page;
        this.totalPages = data.total;
        this.perPage = data.per_page;
        this.nextPageApiUrl = data.next_page_url;
      },
      loadMore() {
        this.$http.get(this.nextPageApiUrl)
          .then(({data}) => {
            this.news = [...this.news, ...data.data];
            this.dataFromApi(data);
          })
          .catch(err => {
            console.log(err);
          });
      },
      sort(event) {
        const sort = event.target.id === 'newest'
          ? {sort_by: 'published_date', sort_order: 'desc'}
          : {sort_by: 'views', sort_order: 'desc'};

        this.$http.get(`${API_ROOT}/api/news`, {params: sort})
          .then(({data}) => {
            this.news = data.data;
            this.dataFromApi(data);
          })
          .catch(err => {
            console.log(err);
          });
      },
      getImagePath(path) {
        return API_ROOT + path;
      }
    },
    mounted() {
      this.$http.get(`${API_ROOT}/api/news`)
        .then(({data}) => {
          this.news = data.data;
          this.dataFromApi(data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
</script>

<style scoped>

</style>
