<template>
  <div class="news_slider_block">
    <div class="news_slider_container">
      <div class="news_slider_arrow_left" @click="prevPage"/>
      <div class="news_slider_arrow_right" @click="nextPage"/>
      <carousel
        :per-page="1"
        navigationNextLabel="next"
        paginationActiveColor="#6F4189"
        paginationColor="white"
        :navigateTo="manualNavigation"
      >
        <slide v-for="item in news">
          <div class="news_slider_item">
            <div class="news_slider_title">{{$t('header.news')}}</div>
            <div class="news_slider_info_block">
              <div class="news_slider_date">{{ $t('published') }}: {{ item.published_date | formatDate('DD.MM.YY') }}</div>
              <div class="news_slider_label">
                {{ item.translations.find(({locale}) => locale === $i18n.locale).title }}
              </div>
              <div class="news_slider_text">
                {{ item.translations.find(({locale}) => locale === $i18n.locale).anons }}
              </div>
              <router-link
                :to="{ name: 'news-item', params: { id: item.id } }"
                class="news_slider_btn_more"
                exact
              >
                <span>{{$t("show_more")}}</span>
              </router-link>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'SliderNews',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      manualNavigation: 0,
      news: []
    }
  },
  methods: {
    nextPage() {
      this.manualNavigation = this.manualNavigation >= this.news.length - 1
        ? 0
        : this.manualNavigation + 1;
    },
    prevPage() {
      this.manualNavigation = this.manualNavigation <= 0
        ? this.manualNavigation = this.news.length - 1
        : this.manualNavigation - 1;
    },
  },
  mounted() {
    this.$http.get(`${API_ROOT}/api/news?on_index=true`)
      .then(({data}) => {
        this.news = data;
      })
      .catch(err => {
        console.log(err);
      });
  }
}
</script>

<style scoped>

</style>
